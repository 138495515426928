<template>
    <a-layout style="height:100%;">
        <a-layout-content style="height:100%;">
            <div class="yj-conten" style="background-color:#fff;width:calc(100% - 32px);padding-top:20px; min-width:fit-content;min-height:calc(100% - 48px)">
                <a-layout style="height:100%;background:#fff">


                    <a-layout-content style="background-color:#fff;padding:20px 20px 16px;width:1400px;">
                        <a-form-model ref="ruleForm" :model="formModel" :rules="rules">

                            <a-form-item label="门店图片" style="margin-bottom:0px;"
                                         :label-col="formItemLayout.labelCol"
                                         :wrapper-col="formItemLayout.wrapperCol">
                                <a-upload name="image"
                                          :data="ParamData"
                                          list-type="picture-card"
                                          class="avatar-uploader"
                                          action="/FileServer/Upload"
                                          :remove="removeImg"
                                          :fileList="formModel.fileList"
                                          @change="imgChange">
                                    <div v-if="formModel.fileList.length < 1">
                                        <a-icon type="plus" />
                                        <div class="ant-upload-text">
                                            上传图片
                                        </div>
                                    </div>
                                </a-upload>
                            </a-form-item>
                            <a-form-item label="门店名称" style="margin-bottom:0px;"
                                         :label-col="formItemLayout.labelCol"
                                         :wrapper-col="formItemLayout.wrapperCol">
                                <a-form-model-item has-feedback prop="name">
                                    <a-input placeholder="门店名称" type="text" style="width:700px" v-model="formModel.name"></a-input>
                                </a-form-model-item>
                            </a-form-item>

                            <a-form-item label="电话" style="margin-bottom:0px;"
                                         :label-col="formItemLayout.labelCol"
                                         :wrapper-col="formItemLayout.wrapperCol">

                                <a-form-model-item has-feedback prop="linkphone">
                                    <a-input placeholder="电话" v-model="formModel.linkphone" style="width: 700px
"></a-input>

                                </a-form-model-item>

                            </a-form-item>       <a-form-item label="联系人" style="margin-bottom:00px;"
                                                              :label-col="formItemLayout.labelCol"
                                                              :wrapper-col="formItemLayout.wrapperCol">

                                <a-form-model-item has-feedback prop="linkman">
                                    <a-input placeholder="联系人" v-model="formModel.linkman" style="width: 700px
"></a-input>
                                </a-form-model-item>

                            </a-form-item>
                            <a-form-item label="地理位置" style="margin-bottom:0px;"
                                         :label-col="formItemLayout.labelCol"
                                         :wrapper-col="formItemLayout.wrapperCol">
                                <a-form-model-item has-feedback prop="address">
                                    <!--<a-input placeholder="联系电话" v-model="formModel.name"></a-input>-->
                                    <a-input placeholder="地理位置" v-model="formModel.address" style="width: 700px
" class="margin-r"></a-input>
                                    <a-button id="mytrainMap" value="地图" @click="showMap">
                                        <span class="glyphicon glyphicon-map-marker" aria-hidden="true"></span>打开点图选点
                                    </a-button>

                                </a-form-model-item>

                            </a-form-item>

                        </a-form-model>
                    </a-layout-content>

                    <a-layout-footer style="height:64px;padding:0 0 16px 0 ;background-color:#fff;">
                        <a-row>
                            <a-col :span="12">
                                <a-row typeof="flex" justify="space-around" class="textal_r">
                                    <a-col :span="12">
                                        <a-button size="large" type="primary" @click="save('ruleForm')">保存</a-button>
                                    </a-col>   <a-col :span="6">
                                        <a-button size="large" centered Vertically @click="cancle">取消</a-button>

                                    </a-col>

                                </a-row>
                            </a-col>
                        </a-row>
                    </a-layout-footer>
                </a-layout>

            </div>

        </a-layout-content>

        <a-modal v-model="modelDisplay"
                 id="BDMap"
                 title="地理位置"
                 centered
                 okText="确定"
                 cancelText="取消"
                 width="90%"
                 height="900px"
                 @ok="confirmAddress">
            <!--// 搜索定位代码-->
            <a-input type="text" id="suggestId" v-model="tempaddress" @keyup.enter="enterEvent" placeholder="请输入地址" />
            <div id="searchResultPanel" style="border:1px solid #C0C0C0;width:150px;height:auto; display:none;"></div>
            <div style="width: 100%; height: 700px;">
                <baidu-map id="container" class="bm-view" ak="E8f3c743837925ce120eb93417f478db" :center="center" :zoom="zoom" :scroll-wheel-zoom="true" @ready="handler"   @click="test" style="margin-top:-40px;">
                    <bm-marker :position="markPoint" >
                    </bm-marker>
                    <bm-info-window :position="markPoint" title="" :show="infoWindow.show" :offset="{width: 2, height: 3}">
                        <p v-text="infoWindow.contents"></p>
                    </bm-info-window>
                </baidu-map>
            </div>
            <div id="l-map" style="display:none;"></div>
        </a-modal>
    </a-layout>

</template>
<style>
    .bm-view {
        width: 100%;
        height: 700px;
    }

    #suggestId {
        width: 500px;
        top: -68px;
        margin-left: 80px;
    }
</style>
<script src="https://api.map.baidu.com/api?v=2.0&ak=E8f3c743837925ce120eb93417f478db"></script>
<script type="text/javascript">

    import http from "@/Plugin/Http.js";
    import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
    import { BmMarker } from 'vue-baidu-map'
    import { BmInfoWindow } from 'vue-baidu-map'
    import { message } from 'ant-design-vue'
    import util from "@/Plugin/util.js";
    // import BMap from 'BMap'
    export default {
        name: "SiteDetail",
        components: {
            BaiduMap,
            BmMarker,
            BmInfoWindow
        },
        data() {
            return {
                pos: "",
                tempaddress: '',
                active: true,
                center: { lng: 114.383176, lat: 36.062233 },
                markPoint: { lng: 114.383176, lat: 36.062233 },
                zoom: 16,
                modelDisplay: false,
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 2 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 21 },
                    },
                },
                ParamData: {
                    ProcessorName: "Image",
                    JsonDataParams: ""
                },
                infoWindow: {
                    show: false,
                    contents: '',
                    offset: {}
                },
                formModel: {
                    id: this.$route.query.ID,
                    custmerid: '',
                    name: '',
                    address: '',
                    linkman: '',
                    linkphone: '',
                    fileList: [],
                    lat: -1,
                    lng: -1
                },
                rules: {
                    name: { validator: this.validateUserName, trigger: 'change', required: true },
                    //linkman: { validator: this.validateUserName, trigger: 'change', required: true },
                    //linkphone: { validator: this.validatelinkphone, trigger: 'change', required: true },
                    address: { validator: this.validateUserName, trigger: 'change', required: true }
                },
                isloadac: 0
            };
        },

        methods: {
            showMap: function () {
                var self = this;
                self.modelDisplay = true;
                /*  self.tempaddress = self.formModel.address;*/

            },
           
            //点击地图确定事件
            confirmAddress: function () {
                var self = this;
                console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
                console.log(self.tempaddress);
                console.log(self.formModel.address);
                console.log(self.center.lng);
                console.log(self.center.lat);
                console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
                self.formModel.address = self.formModel.address;
                self.markPoint.lng = self.center.lng;
                self.markPoint.lat = self.center.lat;
                self.tempaddress = null;
                self.modelDisplay = false;

            },
             dragend: function (type) {
                var self = this;
                self.circlePath.center = type.point;
                self.center.Lng = type.point.lng;
                self.center.Lat = type.point.lat;

                self.markPoint.lng = type.point.lng;
                self.markPoint.lat = type.point.lat;
                self.gec.getLocation(type.point, function (rs) {
                    var addComp = rs.addressComponents;
                    var trainPlace =
                        addComp.province +
                        addComp.city +
                        addComp.district +
                        addComp.street +
                        addComp.streetNumber + addComp.business;
                    var content = trainPlace;
                    self.tempaddress = "";
                     self.FormModel.address = content;
                    console.log("同步位置", self.formModel.address);
                    self.infoWindow.contents = trainPlace + " 经度：" + type.point.lng + " 纬度：" + type.point.lat;
                });
            },
            handler({ BMap, map }) {
                 console.log(map);
                 var self = this;
                 self.zoom = 18;
                 self.gec = new BMap.Geocoder();
                self.center.Lng = self.markPoint.lng;
                self.center.Lat = self.markPoint.lat;
            },
            test: function (type, target, point, pixel, overlay) {
                console.log(type);
                console.log(target);
                console.log(point);
                console.log(pixel);
                console.log(overlay);

                var self = this;
                self.markPoint = type.point
                self.infoWindow.show = true;

                var geoc = new BMap.Geocoder();
                geoc.getLocation(type.point, function (rs) {

                    var addComp = rs.addressComponents;

                    var trainPlace = addComp.province + addComp.city + addComp.district + addComp.street + addComp.streetNumber;

                    var content = trainPlace;// "<br/><br/>经度：" + myPoint.lng + "<br/>纬度：" + myPoint.lat;

                    console.log("只是位置？", content);

                    //self.tempaddress = content;
                    self.formModel.address = content;
                    console.log("同步位置", self.formModel.address);
                    self.infoWindow.contents = content + " 经度：" + type.point.lng + " 纬度：" + type.point.lat;
                    self.center.lng = type.point.lng;
                    self.center.lat = type.point.lat;
                    console.log(self.infoWindow.contents);

                });


            },

            imgChange: function (info) {
                var self = this;
                if (info.file.status === 'error') {
                    if (info.file.response && info.file.response.Message) {
                        this.$message.error(info.file.response.Message);
                    }
                    else {
                        this.$message.error(`${info.file.name} 文件上传失败`);
                    }
                    return;
                }
                if (info.file.status === 'done') {
                    info.file.url = info.file.response;

                }
                self.formModel.fileList = info.fileList;


            },
            removeImg: function (data) {
                console.log(data);
            },
            validateUserName: function (rule, value, callback) {
                if (value === '') {
                    callback(new Error('该字段不能为空'));
                } else {
                    callback();
                }
            },
            validatelinkphone: function (rule, value, callback) {
                if (value === '') {
                    callback(new Error('该字段不能为空'));
                } else {
                    if (util.GetPhone(value) === '') {
                        callback(new Error('请输入正确的联系电话'));
                    } else {
                        callback();
                    }
                }
            },
            getMapCenter: function () {
                var self = this;
                var op = {
                    url: "/ShopModule/ShopManage/GetSiteLngLat",
                    data: {

                    },
                    OnSuccess: function (data) {
                        self.center.lng = data.data.Lng;
                        self.center.lat = data.data.Lat;

                        self.markPoint.lng = data.data.Lng;
                        self.markPoint.lat = data.data.Lat;
                    }
                };

                http.Post(op);
            },
            enterEvent:function(){
                    // console.log('eeee', e);
                    // var _value = value;
                    // myValue = _value.province + _value.city + _value.district + _value.street + _value.business;
                    // console.log("myValue:", myValue);
                    // document.querySelector("#searchResultPanel").innerHTML = "onconfirm<br />index = " + e.item.index + "<br />myValue = " + myValue;
                    // setPlace();
                    // self.formModel.address = myValue;
                    // self.tempaddress = myValue;
                    // console.log("现在地址：", self.tempaddress);

                    // console.log("同步位置2", self.formModel.address)
                    var self=this;
                   self.setPlace(self.tempaddress);
            },
            getData: function () {
                var self = this;
                //var id = this.$route.query.ID;
                //if (id == undefined)
                //    return;
                //else
                //    self.formModel.id = id;

                var op = {
                    url: "/ShopModule/ShopManage/GetSiteDetail",
                    data: {
                        id: self.formModel.id
                    },
                    OnSuccess: function (data) {
                        console.log("注意辨识3：", data);
                        self.formModel.name = data.data.Name;
                        self.formModel.linkphone = data.data.Phone;
                        self.formModel.linkman = data.data.LinkMan;
                        self.formModel.address = data.data.Address;
                        self.formModel.custmerid = data.data.CustmerID;
                        // self.pos = data.data.

                        if (data.data.Picture != "" && data.data.Picture != null) {
                            self.formModel.fileList = [{
                                name: data.data.Picture,
                                response: data.data.Picture,
                                status: "done",
                                uid: "0",
                                url: data.data.Picture
                            }];
                        }

                        //获取当前位置信息
                        if (data.data.Lng != "") {
                            var marker = { lng: data.data.Lng, lat: data.data.Lat };
                            self.markPoint = marker;
                            self.center = marker;
                            console.log("当前位置:", marker);
                        }


                        //Picture: self.formModel.fileList[0].response

                    }
                };
                http.Post(op);
            },

            save: function (formName) {
                var self = this;

                var pos = self.markPoint.lng + "," + self.markPoint.lat
                this.$refs[formName].validate(valid => {


                    if (valid) {
                        var op = {
                            url: "/ShopModule/ShopManage/UpdateSite",
                            data: {
                                ID: self.formModel.id,
                                Name: self.formModel.name,
                                Phone: self.formModel.linkphone,
                                LinkMan: self.formModel.linkman,
                                Address: self.formModel.address,
                                Position: pos,
                                Picture: self.formModel.fileList.length > 0 ? self.formModel.fileList[0].response : ""
                            },
                            OnSuccess: function (data) {

                                if (data.data == "True") {
                                    var display = "新增成功";
                                    if (self.formModel.id != null && self.formModel.id != "") {
                                        display = "编辑成功";
                                    }

                                    message.success(
                                        display,
                                        1.5,
                                        function () {
                                            //self.$router.push({
                                            //    name: "ShopManage_ShopManage", query: {
                                            //        showName: "SiteList"
                                            //    }
                                            //});

                                            self.$router.push({
                                                name: "ShopManage_SiteList"
                                            })
                                        }
                                    );
                                }
                            }
                        };
                        http.Post(op);
                    } else {
                        return false;
                    }
                });
            },
            cancle() {
                var self = this;
                self.$router.push({
                    name: "ShopManage_SiteList", query: {
                        showName: "SiteList"
                    }
                })
            },
            setPlace(myValue) {
                var self = this;
                var map = new BMap.Map("l-map");
                    map.clearOverlays();    //清除地图上所有覆盖物
                    function myFun() {
                        var pp = local.getResults().getPoi(0).point;    //获取第一个智能搜索的结果
                        console.log("pp", pp);
                        self.center.lat = pp.lat;
                        self.center.lng = pp.lng;
                        self.markPoint.lat=pp.lat;
                        self.markPoint.lng=pp.lng;
                        self.infoWindow.contents = self.formModel.address + " 经度：" +  pp.lng + " 纬度：" + pp.lat;
                        console.log(self.center);
                        map.setCenter(pp);
                        map.addOverlay(new BMap.Marker(pp));
                        //添加标注
                    }
                    var local = new BMap.LocalSearch(map, { //智能搜索
                        onSearchComplete: myFun
                    });

                    local.search(myValue);
                }
        },
        mounted() {
            var self = this;
            if (self.formModel.id != null && self.formModel.id != "") {
                self.getData();
            } else {
                self.getMapCenter();
            }
        },
        watch: {
            "tempaddress": function () {
                console.log("监听");
                console.log("123567:");

                var self = this;
                if (self.isloadac)
                    return 1;
                self.isloadac = 1;
                // 创建Map实例
                var map = new BMap.Map("l-map");
                console.log(map)
                var ac = new BMap.Autocomplete({
                    "input": 'suggestId',
                    "location": map
                })
                ac.addEventListener("onhighlight", function (e) {  //鼠标放在下拉列表上的事件
                    var str = "";
                    var _value = e.fromitem.value;
                    var value = "";
                    if (e.fromitem.index > -1) {
                        value = _value.province + _value.city + _value.district + _value.street + _value.business;
                    }
                    str = "FromItem<br />index = " + e.fromitem.index + "<br />value = " + value;

                    value = "";
                    if (e.toitem.index > -1) {
                        _value = e.toitem.value;
                        value = _value.province + _value.city + _value.district + _value.street + _value.business;
                    }
                    str += "<br />ToItem<br />index = " + e.toitem.index + "<br />value = " + value;
                    document.querySelector("#searchResultPanel").innerHTML = str;

                });

                var myValue;
                ac.addEventListener("onconfirm", function (e) {    //鼠标点击下拉列表后的事件
                    console.log('eeee', e);
                    var _value = e.item.value;
                    myValue = _value.province + _value.city + _value.district + _value.street + _value.business;
                    console.log("myValue:", myValue);
                    document.querySelector("#searchResultPanel").innerHTML = "onconfirm<br />index = " + e.item.index + "<br />myValue = " + myValue;
                    setPlace();
                    self.formModel.address = myValue;
                    self.tempaddress = myValue;
                    console.log("现在地址：", self.tempaddress);

                    console.log("同步位置2", self.formModel.address);
                });
                function setPlace() {
                    map.clearOverlays();    //清除地图上所有覆盖物
                    function myFun() {

                        var pp = local.getResults().getPoi(0).point;    //获取第一个智能搜索的结果
                        console.log("pp", pp);
                        self.center.lat = pp.lat;
                        self.center.lng = pp.lng;
                        self.markPoint.lat=pp.lat;
                        self.markPoint.lng=pp.lng;
                        self.infoWindow.contents = self.formModel.address + " 经度：" +  pp.lng + " 纬度：" + pp.lat;
                        console.log(self.center);
                        map.setCenter(pp);
                        map.addOverlay(new BMap.Marker(pp));
                        //添加标注
                    }
                    var local = new BMap.LocalSearch(map, { //智能搜索
                        onSearchComplete: myFun
                    });

                    local.search(myValue);

                }

            }
        },

    };
</script>
<style>
    .tangram-suggestion {
        z-index: 199999
    }
</style>

